<script>
export default {
	lang: 'aboutUs',
	metaInfo() {
		return { title: this.$lang('¿Quiénes somos?') }
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<OtherPageLayout>
		<template #title>
			{{ '¿Quiénes somos?' | lang }}
		</template>
		<p>
			<b>{{ '¿Cómo nos gusta pensar los regalos?' | lang }}</b>
		</p>
		<p>
			{{
				'Hacer un obsequio original puede ser abrumador. A veces es difícil no caer en la compra de un juguete, un accesorio, una pelota, ropa… Pero en Plickme encontramos una alternativa excelente y pensada para ti: libros personalizados.'
					| lang
			}}
		</p>
		<p>
			{{
				'¿Imaginaste alguna vez que tu hijo, nieto, sobrino…o aquella persona especial puedan ser protagonistas de historias apasionantes? Obsequiar un libro siempre es un gesto hermoso que permite a quien lo recibe descubrir nuevos mundos; y se vuelve más hermoso aún si ese libro es personalizado, es decir, si tiene el nombre de esa persona especial en la portada y a lo largo de toda la historia. Esto es lo que convierte a los libros de Plickme en el regalo ideal: llevan la personalización al máximo nivel y convierten a niños y adultos en los héroes de sus propias aventuras.'
					| lang
			}}
		</p>
		<p>
			{{
				'¿Te gustaría que tus personas favoritas disfruten de una lectura original, fortalezcan su autoestima y obtengan herramientas para lidiar con los problemas del mundo real? Entonces, estás en el lugar correcto: cada libro de esta tienda transmite valores que nos enriquecen como personas, tales como la responsabilidad, la solidaridad, la gratitud, la honestidad, la empatía…'
					| lang
			}}
		</p>
		<p>
			{{
				'Para nosotros, cada persona es única y merece tener un libro personalizado; un regalo original que no se puede conseguir en una librería, uno que permita crear momentos únicos, esos que con el paso del tiempo, permanecen en nuestra memoria.'
					| lang
			}}
		</p>
		<p>
			{{
				'Nuestros productos pueden regalarse para cualquier ocasión: cumpleaños, nacimientos, bautismos, días festivos o solo para decir “te quiero” de una forma diferente. Todos los momentos son buenos ya que cada historia es atemporal y personalizada.'
					| lang
			}}
		</p>
		<p>
			<b>{{ '…los primeros pasos' | lang }}</b>
		</p>
		<p>
			{{
				'Nuestra primera colección de libros, Mi Astralito, nació hace dos años. ¿Cómo? En Plickme nos pusimos a garabatear proyectos con un objetivo: generar historias únicas, apasionantes y personalizadas para niños. Y se nos ocurrió jugar con los signos del zodíaco.'
					| lang
			}}
		</p>
		<p>
			{{
				'Pese a las creencias astrológicas de cada uno, imaginamos que podía ser interesante utilizar las características de personalidad propias de cada signo para contar una historia cuyos protagonistas fueran los niños y que, además, pudiéramos incluir sus nombres en la misma. Así no solo se sentirían identificados con la forma en la que el héroe atraviesa las aventuras sino también al escuchar sus nombres.'
					| lang
			}}
		</p>
		<p>
			{{
				'Después de un año de pensar, repensar, corregir, ilustrar y editar las historias, nacieron los 12 cuentos de Mi Astralito. Fue un trabajo arduo y artesanal, que se imprime de a uno, pero que detrás tuvo (y tiene) la dedicación de cada integrante de Plickme.'
					| lang
			}}
		</p>
		<p>
			{{
				'Nuestra idea es continuar en este camino de la personalización y dar un paso más; ofrecer más libros y otros productos como nuestra familia de stickers, cuadernos con actividades muy divertidas para resolver en familia, muñecos de apegos, y decenas de ideas más que irán tomando forma e iremos acercándote.'
					| lang
			}}
		</p>
		<p>
			{{
				'Hoy contamos con títulos que abordan diversas temáticas no solo para entretener sino para acompañar el crecimiento de los peques: aprender las letras del abecedario, los números del 1 al 10; conocer los animales (cocodrilos, pulpos, ballenas, tigres, elefantes, leones, monos…), las fases de la luna… Y para los más grandes, frases y citas inspiracionales. Todos creados bajo una visión didáctica, divertida y disponibles en español, inglés y portugués.'
					| lang
			}}
		</p>
		<p>
			{{
				'Así que desde Plickme te invitamos a descubrir el mundo de la personalización de libros. A convertirte en protagonista y héroe de tus propias historias y a estar a un click de poder hacer el regalo más original, único e irrepetible.'
					| lang
			}}
		</p>
	</OtherPageLayout>
</template>

<style></style>
