<script>
import img from '../assets/FreeIssue-Amistad.png'
const ZODIAC_SIGNS = [
	'Aries',
	'Tauro',
	'Géminis',
	'Cáncer',
	'Leo',
	'Virgo',
	'Libra',
	'Escorpio',
	'Sagitario',
	'Capricornio',
	'Acuario',
	'Piscis',
]
export default {
	pageView: true,
	data() {
		return {
			img,
			loading: false,
			error: false,
			validation: {},
			form: {
				nameFrom: '',
				nameTo: '',
				email: null,
				zodiac: null,
			},
			zodiacSigns: ZODIAC_SIGNS,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
	methods: {
		submitForm() {
			if (this.error) return
			if (this.$route.query.origin) {
				this.form.origin = this.$route.query.origin
			}
			this.$shopApi.post({
				url: '/free-issue-form/amistad',
				data: this.form,
				loading: (v) => (this.loading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				done: (data) => {
					console.log(data)
					const { success } = data
					if (success) {
						this.$router.push({
							name: 'others.free-issue-thanks',
							params: { key: 'amistad', email: this.form.email },
							query: this.$route.query,
						})
					} else {
						this.error = true
						setTimeout(() => (this.error = false), 4000)
					}
				},
			})
		},
	},
	mounted() {
		this.$prerenderReady()
		console.log('created')
	},
}
</script>
<template>
	<div class="page-bg-container">
		<div class="d-flex flex-column align-center justify-center pb-10">
			<Container width="800px">
				<div class="darkblue--text font-weight-bold text-center mb-3 font-6 font-md-10">
					Consigue un regalo personalizado GRATIS para tu persona favorita.
				</div>
				<div class="text-center mb-3 font-2">
					Descubran juntos datos divertidos y curiosos sobre su signo. ¡Actividades para compartir, o el
					mejor destino para viajar según su signo!
				</div>
			</Container>
			<div class="darkblue w100 my-10">
				<Container>
					<v-row>
						<v-col cols="12" md="6" class="pt-16 px-md-16 d-flex justify-center align-center">
							<Media :src="img" width="100%" class="rounded-lg overflow-hidden" contain></Media>
						</v-col>
						<v-col cols="12" md="6" class="py-10">
							<div class="white--text font-6 text-center mb-8">
								Ingresa estos datos y <br />
								¡recibe tu REGALO PERSONALIZADO ahora mismo!
							</div>
							<Validator :validation="validation">
								<div>
									<label class="white--text">Tu nombre</label>
									<TextField
										v-model="form.nameFrom"
										validator-key="nameFrom"
										placeholder="Ingresa tu nombre aqui"
										class="customization-tf mb-3"
										solo
										height="50"
										counter="14"
										maxlength="14"
									/>
									<label class="white--text">El nombre de tu persona favorita</label>
									<TextField
										v-model="form.nameTo"
										validator-key="nameTo"
										placeholder="Ingresa tu nombre aqui"
										class="customization-tf mb-3"
										solo
										height="50"
										counter="14"
										maxlength="14"
									/>
									<label class="white--text">Tu correo</label>
									<TextField
										v-model="form.email"
										validator-key="email"
										placeholder="Ingresa tu correo electronico aqui"
										class="customization-tf mb-3"
										solo
										height="50"
									/>
									<label class="white--text">Tu signo zodiacal</label>
									<Select
										:items="zodiacSigns"
										validator-key="zodiac"
										placeholder="Selecciona tu signo zodiacal"
										solo
										v-model="form.zodiac"
									>
									</Select>
								</div>
							</Validator>
							<Button
								:color="error ? 'white' : 'orange'"
								:text="error"
								class="white--text px-15 mt-8"
								block
								x-large
								@click="submitForm"
								:loading="loading"
							>
								<span v-if="error">Oops! Ocurrio un error. Intenta nuevamente.</span>
								<span v-else>¡Lo quiero ahora!</span>
							</Button>
						</v-col>
					</v-row>
				</Container>
			</div>
			<!-- <Media
				:src="isMobile ? mobileBottomImg : bottomImg"
				width="100%"
				max-width="950px"
				max-height="350px"
				contain
			/> -->
		</div>
	</div>
</template>

<style scoped>
.input {
	border-right: 1px solid lightgray;
}
.input >>> input {
	color: var(--v-darkblue-base);
}
.v-text-field ::v-deep .v-counter {
	color: white;
}
</style>
