<script>
const messages = {
	base: (email) =>
		`¡Listo! En unos minutos encontrarás tu libro personalizado en tu correo, ${email}. Si no lo encuentras, no dejes de revisar tu casilla de correo no deseado.`,
	amor: (email) =>
		`¡Listo! En unos minutos encontrarás tu frases y citas personalizadas en tu correo, ${email}. Si no lo encuentras, no dejes de revisar tu casilla de correo no deseado.`,
	abc: (email) =>
		`¡Listo! En unos minutos encontrarás tu regalo personalizado en tu correo, ${email}. Si no lo encuentras, no dejes de revisar tu casilla de correo no deseado.`,
	'letter-santa-clous': (email) =>
		`¡Listo! En unos minutos encontrarás tu carta para Papá Noel con el nombre de tu peque en tu correo (${email}). Si no la encuentras en la bandeja principal, no dejes de revisar tu casilla de correo no deseado.`,
	zodiacario: (email) =>
		`¡Perfecto! En unos minutos recibirás tu regalo personalizado en tu correo electrónico, ${email} Si no lo encuentras, asegúrate de revisar tu carpeta de correo no deseado.`,
	tareas: (email) =>
		`¡Perfecto! En unos minutos recibirás tu regalo personalizado en tu correo electrónico, ${email} Si no lo encuentras, asegúrate de revisar tu carpeta de correo no deseado.`,
	amistad: (email) =>
		`¡Perfecto! En unos minutos recibirás tu tabla de tareas personalizada en tu correo electrónico, ${email} Si no lo encuentras, asegúrate de revisar tu carpeta de correo no deseado.`,
}
export default {
	pageView: true,
	created() {
		if (!this.$route.params.email) {
			this.$router.replace(this.$shop.getShopRoute())
		}
	},
	mounted() {
		this.$prerenderReady()
	},
	computed: {
		message() {
			const { key, email } = this.$route.params
			let fn = messages[key] || messages.base
			return fn(email)
		},
	},
}
</script>
<template>
	<div class="page-bg-container">
		<Container class="text-center py-15">
			<div class="darkblue--text font-weight-bold text-center mb-3 font-6 font-md-8">
				{{ message }}
			</div>

			<Button
				class="orange white--text px-md-15 mt-10 mb-15"
				:x-large="!$vuetify.breakpoint.smAndDown"
				@click="$shop.goShop()"
			>
				Explora nuestros productos
			</Button>
		</Container>
	</div>
</template>
