<script>
import img from '../assets/top-img.png'
import bottomImg from '../assets/bottom-img.png'
import mobileBottomImg from '../assets/mobile-bottom-img.png'

export default {
	pageView: true,
	data() {
		return {
			img,
			bottomImg,
			mobileBottomImg,
			loading: false,
			error: false,
			validation: {},
			form: {
				nameTo: null,
				nameFrom: null,
				email: null,
				inscription: null,
			},
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
	methods: {
		submitForm() {
			if (this.error) return
			if (this.$route.query.origin) {
				this.form.origin = this.$route.query.origin
			}
			this.$shopApi.post({
				url: '/free-issue-form/free-book',
				data: this.form,
				loading: (v) => (this.loading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				done: ({ success }) => {
					if (success) {
						this.$router.push({
							name: 'others.free-issue-thanks',
							params: { key: 'free-book', email: this.form.email },
							query: this.$route.query,
						})
					} else {
						this.error = true
						setTimeout(() => (this.error = false), 4000)
					}
				},
			})
		},
	},
	mounted() {
		this.$prerenderReady()
	},
}
</script>
<template>
	<div class="page-bg-container">
		<div class="d-flex flex-column align-center justify-center pb-10">
			<Container width="800px">
				<div class="darkblue--text font-weight-bold text-center mb-3 font-6 font-md-10">
					Crea un libro personalizado GRATIS para sorprender a alguien especial.
				</div>
				<div class="text-center mb-3 font-2">
					Un libro que reúne las mejores frases y reflexiones para decirle "Te quiero". Agrega su nombre
					y el tuyo. ¡Creálo ahora y recíbelo en tu email!
				</div>
			</Container>
			<div class="darkblue w100 py-10 my-10">
				<Container>
					<v-row>
						<v-col cols="12" md="6">
							<Media :src="img" width="100%" contain></Media>
						</v-col>
						<v-col cols="12" md="6">
							<div class="white--text font-6 text-center mb-8">
								Ingresa estos datos <br />
								¡y recibe tu libro ahora mismo!
							</div>
							<Validator :validation="validation">
								<div>
									<label class="white--text">Nombre - para quién es</label>
									<TextField
										v-model="form.nameTo"
										validator-key="nameTo"
										label="Este libro personalizado es para"
										class="customization-tf mb-3"
										solo
										height="50"
										counter="14"
										maxlength="14"
									/>
									<label class="white--text">Tu nombre</label>
									<TextField
										v-model="form.nameFrom"
										validator-key="nameFrom"
										label="Este libro personalizado es de parte de"
										class="customization-tf mb-3"
										solo
										height="50"
										counter="14"
										maxlength="14"
									/>
									<label class="white--text">Dedicatoria</label>
									<TextField
										v-model="form.inscription"
										validator-key="inscription"
										label="Regala una dedicatoria llena de amor"
										class="customization-tf mb-3"
										solo
										height="50"
										counter="130"
										maxlength="130"
									/>
									<label class="white--text">Tu correo</label>
									<TextField
										v-model="form.email"
										validator-key="email"
										label="Tu dirección de correo electrónico"
										class="customization-tf mb-3"
										solo
										height="50"
									/>
								</div>
							</Validator>
							<Button
								:color="error ? 'white' : 'orange'"
								:text="error"
								class="white--text px-15 mt-8"
								block
								x-large
								@click="submitForm"
								:loading="loading"
							>
								<span v-if="error">Oops! Ocurrio un error. Intenta nuevamente.</span>
								<span v-else>Crea tu libro</span>
							</Button>
						</v-col>
					</v-row>
				</Container>
			</div>
			<Media
				:src="isMobile ? mobileBottomImg : bottomImg"
				width="100%"
				max-width="950px"
				max-height="350px"
				contain
			/>
		</div>
	</div>
</template>

<style scoped>
.input {
	border-right: 1px solid lightgray;
}
.input >>> input {
	color: var(--v-darkblue-base);
}
.v-text-field ::v-deep .v-counter {
	color: white;
}
</style>
