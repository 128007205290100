<script>
import { areTranslationsLoaded, addTranslations, langIso } from '@/__shared/lang/lib_lang'
import termsHtml from 'raw-loader!./terms-content.html'

export default {
	lang: 'termspage|content',
	metaInfo() {
		return { title: this.loaded ? this.$lang('Términos y condiciones') : '' }
	},
	data() {
		return {
			loaded: areTranslationsLoaded('termspage'),
		}
	},
	async mounted() {
		if (!this.loaded) {
			if (langIso) {
				await this.$shopApi.get('/lang-translations-by-scope', {
					query: { scope: 'termspage', langIso },
					loading: false,
					onSuccess: ({ data }) => {
						addTranslations(data.translations, 'termspage')
					},
				})
			}
			this.loaded = true
		}
		this.$eventer.trigger('page:view')
		this.$prerenderReady()
	},
	methods: {
		getHtml() {
			let langHtml = this.$langText('terms-content.html')
			return langHtml == 'terms-content.html' ? termsHtml : langHtml
		},
	},
}
</script>

<template>
	<OtherPageLayout :loading="!loaded">
		<template #title>{{ 'Términos y condiciones' | lang }}</template>
		<div class="terms-container" v-html="getHtml()" />
	</OtherPageLayout>
</template>

<style scoped>
.subtitle,
.terms-container ::v-deep .subtitle {
	padding: 16px 0;
	font-size: 1.2rem;
}
</style>
