<script>
export default {
	lang: 'others',
	metaInfo() {
		return { title: this.$lang('Garantía de Satisfacción') }
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<OtherPageLayout>
		<template #title>{{ 'Garantía de Satisfacción' | lang }}</template>
		<p>
			{{
				'One Minetta Media S.A. es una compañía dedicada a proveer productos y servicios de óptima calidad a sus clientes del mundo entero. Todos los productos incluidos en plickme.com han sido cuidadosamente seleccionados para brindarte excelente calidad y el mejor precio. Si al recibir tu pedido no te sintieras satisfecho con tu producto, nos comprometemos a devolverte inmediatamente tu dinero.'
					| lang
			}}
		</p>
		<p>{{ 'Ley de defensa del consumidor. Artículo 34' | lang }}</p>
		<p>
			{{
				'El consumidor tiene derecho a revocar la presente operación comercial (por adquisición de bienes y/o prestación de servicios) durante el plazo de DIEZ (10) días corridos, contados a partir de la fecha en que se entregue el bien o se celebre el contrato, lo último que ocurra, sin responsabilidad alguna. Esta facultad no puede ser dispensada ni renunciada. El consumidor comunicará fehacientemente dicha revocación al proveedor y pondrá el bien a su disposición. Los gastos de devolución son por cuenta del proveedor. Para ejercer el derecho de revocación el consumidor deberá poner el bien a disposición del vendedor sin haberlo usado y manteniéndolo en el mismo estado en que lo recibió, debiendo restituir el proveedor al consumidor los importes recibidos.'
					| lang
			}}
		</p>
		<p>
			{{
				'El tiempo máximo de producción es de 15 días corridos. Una vez producido se despachará por encomienda certificada a través de Correo Argentino.'
					| lang
			}}
		</p>
	</OtherPageLayout>
</template>

<style></style>
