<script>
export default {
	lang: 'faq',
	metaInfo() {
		return { title: this.$lang('Preguntas frecuentes') }
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<OtherPageLayout>
		<template #title> {{ 'Preguntas frecuentes' | lang }} </template>
		<ExpansionPanels
			:items="[
				{
					header: $lang('¿Cómo hago mi libro personalizado?'),
					content: $langText(
						'Es muy fácil: selecciona el libro que quieres regalar, completa los datos que te pedimos en el formulario de personalización, los de envío de tu compra, ¡y listo!'
					),
				},
				{
					header: $lang('¿Cuánto tardan en enviar mi libro personalizado?'),
					content: $langText(
						'Queremos que estés tranquilo, ¡por eso siempre podrás hacer el seguimiento de tu orden! Solo recuerda que al ser un producto a pedido, una vez realizada la compra, el tiempo en el que la recibirás dependerá del domicilio que seleccionaste para la entrega: Para CABA-GBA y destinos fuera de Argentina, se entregará en un máximo de 12 días hábiles. Para el interior, en 15 días hábiles.\n\nSi prefieres retirarlo por nuestra sucursal, en un máximo de 8 días hábiles estará disponible.\n\nRecibirás notificaciones en tu casilla de correo electrónico con el estado de tu libro (recuerda siempre chequear la casilla de spam).'
					),
				},
				{
					header: $lang('¿Cómo realizan el envío?'),
					content: $langText(
						'Si estás en Argentina y seleccionas la opción envío a domicilio, despacharemos tu compra por Correo Argentino. Si estás o envías tu compra a otro país, llegará a través de FedEx. Una vez que tu pedido sea enviado, recibirás un correo electrónico con los datos necesarios para que puedas realizar su seguimiento.'
					),
				},
				{
					header: $lang(
						'No he recibido ningún email de confirmación ¿quiere decir que mi compra no fue procesada?'
					),
					content: $langText(
						'Luego de recibir cada compra, enviamos un correo electrónico de confirmación con los datos de tu pedido. Sin embargo, en el caso de que no lo hayas recibido, te recomendamos revisar la bandeja de correo electrónico no deseado (también conocido como spam).\n\nSi efectivamente nuestro correo lo encontraste en la casilla de correo no deseado, recuerda marcarnos como correo electrónico válido, de esta forma nuestras notificaciones aparecerán en tu bandeja principal.\n\nSi ocurriera que no estamos tampoco como correo no deseado, te recomendamos buscar en la pestaña de Promociones.\n\nSi luego de verificar todas estas instancias previas continúas sin tener nuestro correo electrónico, por favor escríbenos a nuestro WhatsApp (+5491127612538) o Correo electrónico (plickme@oneminetta.com). En Plickme siempre habrá una persona para ayudarte.'
					),
				},
				{
					header: $lang('Tengo un cupón de descuento ¿dónde lo valido?'),
					content: $langText(
						'Para obtener tu beneficio, debes introducir tu código de cupón en el campo Cupón de descuento en el módulo Aplica descuento. El mismo aparecerá en tu pantalla luego de que tengas tus productos en el carrito y presiones: finalizar compra.'
					),
				},
				{
					header: $lang('¡Me equivoqué en los datos! ¿Puedo modificar mi pedido?'),
					content: $langText(
						'Una vez que termines de personalizar tus libros, te aparecerá la pantalla del carrito. Ahí verás todos tus productos y en cada uno, un botón que dice editar. Haciendo click allí, puedes ir nuevamente a tu pedido y hacer las modificaciones que desees.\n\nUna vez que hayas realizado el pago de tu compra, no tendrás la posibilidad de hacer cambios, por eso te recomendamos revisar detenidamente tu orden antes de terminar tu compra.'
					),
				},
				{
					header: $lang('¿Para qué edades son los libros de Plickme?'),
					content: $langText(
						'La mayoría de nuestros libros están recomendados para niños de entre 0 y 8 años, pero también podrás encontrar títulos para mayores de 15 años.\n\nSi quieres saber la edad recomendada de alguno en específico, te recomendamos ir a la descripción del mismo y conocer las especificaciones.'
					),
				},
				{
					header: $lang('¿En qué idioma puedo personalizar los libros de Plickme?'),
					content: $langText('Nuestros libros están disponibles en español, inglés y portugués.'),
				},
				{
					header: $lang('¿Puedo ver cómo es mi libro antes de encargarlo?'),
					content: $langText(
						'Luego de seleccionar el libro que quieres, puedes previsualizarlo online y ver por adelantado, cómo la historia cobra vida con el nombre de tu peque antes de finalizar tu compra.'
					),
				},
			]"
			class="mt-4"
			content-class="my-4"
			panel-class="py-2"
		/>
	</OtherPageLayout>
</template>
