<script>
export default {
	props: {
		loading: Boolean,
	},
}
</script>
<template>
	<div class="page-bg-container">
		<Container class="py-14" width="600" v-if="loading">
			<v-progress-linear color="darkblue" indeterminate />
		</Container>
		<Container class="pt-4 pb-16" v-else>
			<div class="orange--text font-7 py-2 pl-4 mb-8" style="border-left: 10px solid var(--v-orange-base)">
				<slot name="title" />
			</div>
			<slot />
		</Container>
	</div>
</template>
